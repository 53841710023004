<template>
  <div class="list">
    <Breadcrumb name1="艺术品系列" name2="系列列表" />
    <el-card>
      <!-- 按钮搜索/添加区域 -->
      <el-row :gutter="20">
        <el-col :span="4">
          <el-button type="primary" @click="jumpToAddSeries">添加系列</el-button>
        </el-col>
      </el-row>
      <!-- table区域 -->
      <el-row>
        <el-table :data="goodsList" border style="width: 100%">
          <el-table-column prop="id" label="ID" width="70"></el-table-column>
          <el-table-column prop="name" label="系列名称"></el-table-column>
          <el-table-column label="系列背景图">
            <template v-slot="scope">
              <el-image 
              :src="scope.row.bg_image"
              style="width:100px" />
            </template>
          </el-table-column>
          <el-table-column prop="artist.nickname" label="艺术家"></el-table-column>
          <el-table-column prop="website_url" label="官网链接"></el-table-column>
          <el-table-column prop="result_url" label="抽奖结果"></el-table-column>
          <el-table-column prop="sort" label="排序"></el-table-column>
          <el-table-column label="操作">
            <template v-slot="scope">
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-edit"
                @click="editById(scope.row.id)"
              ></el-button>
              <el-button
                type="danger"
                size="mini"
                icon="el-icon-delete"
                @click="removeById(scope.row.id)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryInfo.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import Breadcrumb from 'components/content/breadcrumb/Breadcrumb'
import { number } from 'echarts'
export default {
  name: 'List',
  components: {
    Breadcrumb,
  },
  data() {
    return {
      generateLoading:false,
      // 查询参数对象
      queryInfo: {
        page: 1,
        page_size: 10,
      },
      // 艺术品列表
      goodsList: [],
      // 总数据条数
      total: 0,
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    // 根据分页获取列表数据
    getDataList() {
      const _that = this;
      _that.$http({
        method: 'get',
        url: '/api/v1/admin/art-series/list',
        params: _that.queryInfo,
        timeout: 10000,
        headers: {
          token: window.sessionStorage.getItem('token'),
          // 'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json; charset=UTF-8',
        },
        // loading: loading
      })
        .then((res) => {
          _that.$message.success('获取合成列表成功!')
          _that.goodsList = res.data.list
          _that.total = res.data.pager.total_rows
        })
        .catch((res) => {
          return _that.$message.error('获取合成列表失败!')
        })
    },
    // 显示的页数改变
    handleSizeChange(newSize) {
      this.queryInfo.page_size = newSize
      this.getDataList()
    },
    // 页码的改变
    handleCurrentChange(newValue) {
      this.queryInfo.page = newValue
      this.getDataList()
    },
    async removeById(id){
      const confirmResult = await this.$confirm(
        '此操作将刪除该艺术品, 是否继续?',
        '删除',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      ).catch((err) => err)
      console.log(confirmResult)
      if (confirmResult !== 'confirm') {
        this.$message.info('删除操作已取消!')
        return
      }
      this.$http({
        method: 'delete',
        url: '/api/v1/admin/art-series/' + id,
        timeout: 10000,
        headers: {
          token: window.sessionStorage.getItem('token'),
          'Content-Type': 'application/json; charset=UTF-8',
        },
      })
      .then((res) => {
        if (res.data.status == 200){
          this.$message.success('删除成功!')
        }
        this.getDataList()
      })
      .catch((res) => {
        return this.$message.error('删除失败!')
      })
    },
    formatBoolean: function (row, index) {
      if (row.is_published) {
        return '是' //根据自己的需求设定
      } else {
        return '否'
      }
    },
    editById(id) {
      let route = 'series-add/' + id
      this.$router.push(route)
    },
    jumpToAddSeries: function () {
      var sort = 1
      if (this.goodsList.length > 0){
        sort = this.goodsList[0].id*1+1
      }
      this.$router.push('/series-add?sort='+sort)
    },

  },
}
</script>

<style lang="less" scoped>
.el-table {
  margin: 15px 0 0;
}
</style>
<style>
  .el-dialog{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 0!important;
    transform: translate(-50%, -50%);
  }
</style>